.jsoneditor-tree {
    background-color: white !important;
}

.editConfigJson>.MuiBox-root>button {
    display: none !important;
}

.hideBtn {
    display: none !important;
}