html body {
    height: 100%;
}

#root {
    height: 100%;
}

.map {
    width: 100%;
    height: 100% !important;
    position: fixed !important;
    /*top: 0;
    left: 0;*/
}

.nopadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}