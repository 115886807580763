.btnOpenAlertView {
    position: fixed !important;
    bottom: 0;
    right: 0;
    margin: 15px !important;
}

.blackBtn {
    color: white !important;
    background-color: #111111 !important;
}

.blackBtn:hover {
    background-color: #333333 !important;
}

.redBtn {
    color: white !important;
    background-color: rgb(255, 49, 49) !important;
}

.redBtn:hover {
    background-color: rgb(240, 110, 110) !important;
}

.orangeBtn {
    color: white !important;
    background-color: rgb(255, 149, 49) !important;
}

.orangeBtn:hover {
    background-color: rgb(240, 184, 110) !important;
}

.yellowBtn {
    color: black !important;
    background-color: rgb(251, 195, 41) !important;
}

.yellowBtn:hover {
    background-color: rgb(255, 236, 128) !important;
}

.greenBtn {
    color: white !important;
    background-color: #4caf50 !important;
}

.greenBtn:hover {
    background-color: #43a047 !important;
}

.MuiTooltip-tooltip {
    font-size: 15px !important;
}