@import "~react-leaflet-markercluster/dist/styles.min.css";
.root {
    max-width: 345;
}

.media {
    height: 140;
}

.locationPopup .leaflet-popup-content {
    margin: 0px;
}

.locationPopup .leaflet-popup-content-wrapper {
    padding: 0px
}

.locationPopup .leaflet-popup-tip {
    background-color: #424242;
}

.alertCounter {
    margin-left: 10px;
}

.leaflet-popup-content p {
    margin: 0 !important;
}


/*.marker {
    z-index: 9999 !important;
}*/

.marker p {
    margin-top: 3px;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 27px;
    font-weight: bolder;
    font-family: Roboto;
    /*color: rgb(243, 243, 243) !important;
    /*-webkit-text-stroke: 0.1px black; /* width and color */
    /*text-shadow: -2px 0 rgb(0, 0, 0), 0 2px rgb(0, 0, 0), 2px 0 rgb(0, 0, 0), 0 -2px rgb(0, 0, 0) !important;*/
    color: rgb(0, 0, 0);
    line-height: 25px;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.icondiv-container {
    width: 100%;
    height: 100%;
    text-align: center;
}

.mapPointer {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
}

.pointerShadow {
    background-image: url(../images/marker-shadow.png);
    background-size: contain;
    background-position: center;
    background-position-x: 68%;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
}

.btnOpenCloseLevel {
    position: fixed !important;
    top: 63px;
    right: 42px !important;
    margin: 15px !important;
}

.redBtn {
    color: white !important;
    background-color: rgb(255, 49, 49) !important;
}

.redBtn:hover {
    background-color: rgb(240, 110, 110) !important;
}