.btnOpenTreeView {
    position: fixed !important;
    bottom: 0;
    left: 0;
    margin: 15px !important;
    z-index: 1000;
}

.treeview {
    background-color: #212121;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
    max-height: 80vh !important;
}

.treeview-container {
    min-width: 300px;
    max-width: 450px;
    max-height: 80vh !important;
}

.sliding-panel-container .MuiDrawer-paperAnchorLeft {
    width: 35%;
    min-width: 320px;
    max-width: 500px;
}